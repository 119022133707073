import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";

const mageix = createApp(App);


mageix.use(router)


mageix.mount('#app')
