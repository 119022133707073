export const sms = [
    {
        title: 'Spotify',
        link: 'https://open.spotify.com/artist/72iapZIBpoYp1M94J77jEH?si=xMNrkp3mQnOnV_eD2cceiw',
        fontAwesome: 'fa fa-spotify',
    },
    {
        title: 'Apple Music',
        link: 'https://music.apple.com/de/artist/the-marble-geier-experience/1627068696',
        fontAwesome: 'fab fa-itunes-note',
    },
    {
        title: 'Bandcamp',
        link: 'https://mageix.bandcamp.com/',
        fontAwesome: 'fa fa-bandcamp',
    },
    {
        title: 'Soundcloud',
        link: 'https://soundcloud.com/themarblegeierexperience',
        fontAwesome: 'fa fa-soundcloud',
    },
    // {
    //     title: 'Mixcloud',
    //     link: 'https://open.spotify.com/album/2m3QqlxZiY3WmrIfvMfi6s',
    //     fontAwesome: '',
    // },
    {
        title: 'Youtube',
        link: 'https://www.youtube.com/channel/UC-ZcJ14DpomfXimTre_OzFQ',
        fontAwesome: 'fa fa-youtube',
    },
    {
        title: 'Insta',
        link: 'https://instagram.com/themarblegeierexperience/',
        fontAwesome: 'fa fa-instagram',
    },
    {
        title: 'Facebook',
        link: 'https://www.facebook.com/themarblegeierexperience',
        fontAwesome: 'fa fa-facebook-f',
    },
    {
        title: 'Mail',
        link: 'mailto:zentrale@mageix.ch',
        fontAwesome: 'fa fa-envelope',
    },
]