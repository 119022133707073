export const wisenheimerLinks = [
    {
        id: 1 ,
        title: 'Spotify',
        link: 'https://open.spotify.com/album/7EY8cbivHh5a8X8izBbZ4L?si=FalFOW3kS1GnrOKY0dJu2g',
        fontAwesome: '',
        description: 'Stream'
    },
    {
        id: 1 ,
        title: 'Apple Music',
        link: 'https://music.apple.com/ch/album/wisenheimer-waltz-dumbo-gets-mad-single/1646148915',
        fontAwesome: '',
        description: 'Stream and Buy'
    },
    {
        id: 1 ,
        title: 'iTunes',
        link: 'https://music.apple.com/ch/album/wisenheimer-waltz-dumbo-gets-mad-single/1646148915',
        fontAwesome: '',
        description: 'Stream and Buy'
    },
    // {
    //     id: 1 ,
    //     title: 'Deezer',
    //     link: '',
    //     fontAwesome: '',
    //     description: 'Stream'
    // },
    {
        id: 1 ,
        title: 'Bandcamp',
        link: 'https://mageix.bandcamp.com/album/wisenheimer-waltz-dumbo-gets-mad',
        fontAwesome: '',
        description: 'Stream and Buy'
    },
    {
        id: 1 ,
        title: 'Soundcloud',
        link: 'https://soundcloud.com/themarblegeierexperience/sets/wisenheimer-waltz-dumbo-gets-mad',
        fontAwesome: '',
        description: 'Listen'
    },
    {
        id: 1 ,
        title: 'Youtube',
        link: 'https://youtu.be/6hui95AvaGg',
        fontAwesome: '',
        description: 'Wisenheimer Waltz'
    },
    {
        id: 1 ,
        title: 'Youtube',
        link: 'https://youtu.be/sz1f3yBDaPg',
        fontAwesome: '',
        description: 'Dumbo Gets Mad'
    },
]