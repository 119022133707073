export const itmightgetloudLinks = [
    {
        id: 1 ,
        title: 'Spotify',
        link: 'https://open.spotify.com/album/2m3QqlxZiY3WmrIfvMfi6s',
        fontAwesome: '',
        description: 'Stream'
    },
    {
        id: 1 ,
        title: 'Apple Music',
        link: 'https://music.apple.com/us/album/it-might-get-loud-single/1627085922?uo=4&app=music&at=1001lry3&ct=dashboard',
        fontAwesome: '',
        description: 'Stream and Buy'
    },
    {
        id: 1 ,
        title: 'iTunes',
        link: 'https://music.apple.com/us/album/it-might-get-loud-single/1627085922?uo=4&app=itunes&at=1001lry3&ct=dashboard',
        fontAwesome: '',
        description: 'Stream and Buy'
    },
    // {
    //     id: 1 ,
    //     title: 'Deezer',
    //     link: '',
    //     fontAwesome: '',
    //     description: 'Stream'
    // },
    {
        id: 1 ,
        title: 'Bandcamp',
        link: 'https://mageix.bandcamp.com/track/it-might-get-loud',
        fontAwesome: '',
        description: 'Stream and Buy'
    },
    {
        id: 1 ,
        title: 'Soundcloud',
        link: 'https://soundcloud.com/themarblegeierexperience/it-might-get-loud',
        fontAwesome: '',
        description: 'Listen'
    },
    {
        id: 1 ,
        title: 'Youtube',
        link: 'https://youtu.be/vMaKTBbwbmQ',
        fontAwesome: '',
        description: 'Listen & Watch'
    },
]