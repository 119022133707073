export const remixLinks = [
   {
        id: 1 ,
        title: 'Spotify',
        link: 'https://open.spotify.com/album/15Gkt58On8erAlJCx1Qirz?si=4MMKQRnASU6ciY8dI_tqhA',
        fontAwesome: '',
        description: 'Stream'
    },
    {
        id: 1 ,
        title: 'Apple Music & iTunes',
        link: 'https://music.apple.com/ch/album/remixperience/1685066656',
        fontAwesome: '',
        description: 'Stream and Buy'
    },
    // {
    //     id: 1 ,
    //     title: 'iTunes',
    //     link: 'https://music.apple.com/ch/album/the-marble-geier-experience/1656466464?uo=4',
    //     fontAwesome: '',
    //     description: 'Stream and Buy'
    // },
    // {
    //     id: 1 ,
    //     title: 'Deezer',
    //     link: '',
    //     fontAwesome: '',
    //     description: 'Stream'
    // },
    {
        id: 1 ,
        title: 'Bandcamp',
        link: 'https://mageix.bandcamp.com/album/remixperience',
        fontAwesome: '',
        description: 'Buy & Listen'
    },
    {
        id: 1 ,
        title: 'Soundcloud',
        link: 'https://soundcloud.com/themarblegeierexperience/sets/remixperience',
        fontAwesome: '',
        description: 'Listen'
    },
    {
        id: 1 ,
        title: 'Youtube',
        link: 'https://youtube.com/playlist?list=PLWLvRkIC6AteydBoAnMVdlWHJB41OB75T',
        fontAwesome: '',
        description: 'Full Album Playlist'
    },

    /*{
        id: 1 ,
        title: 'Youtube',
        link: 'https://youtu.be/6hui95AvaGg',
        fontAwesome: '',
        description: 'Wisenheimer Waltz'
    },
    {
        id: 1 ,
        title: 'Youtube',
        link: 'https://youtu.be/sz1f3yBDaPg',
        fontAwesome: '',
        description: 'Dumbo Gets Mad'
    },*/
]