<template>


  <main id="content">
    <router-view :key="$route.path"></router-view>
  </main>


</template>

<script>


export default {
  name: 'App',
}
</script>

<style>
@import "./assets/css/fw.min.css";
@import "./assets/css/style.css";
</style>
