<template>
  <div class="bg background">
    <div class="layer"></div>
  </div>
  <div class="container-fluid">
    <ul class="socials mt-3">
      <li v-for="(sm, i) in sms" :key="i">
        <a target="_blank" v-bind:href="sm.link"><i :class="sm.fontAwesome"></i></a>
      </li>
    </ul>
    <div class="bg canvas can-square mt-3 mb-3 " v-bind:style="{ backgroundImage: 'url(' +  require(`@/assets/img/remixperience.jpg`) + ')' }"></div>
    <div class="row gutb-15px links">
      <div v-for="(link, index) in links" :key="index" class="col-12">
        <a target="_blank" v-bind:href="link.link" class="link">
          <div class="title">{{ link.title }}</div>
          <div class="description">{{ link.description }}</div>
        </a>
      </div>
    </div>
    <ul class="socials mt-6 mb-3">
      <li v-for="(sm, i) in sms" :key="i">
        <a target="_blank" v-bind:href="sm.link"><i :class="sm.fontAwesome"></i></a>
      </li>
    </ul>
  </div>
</template>

<script>
import {remixLinks} from "@/data/remixLinks";
import {sms} from "@/data/socialmedia";

export default {
  name: "RemixView",
  data() {
    return {
      links: remixLinks,
      sms: sms
    };
  },
}
</script>

<style scoped>
.links {
  font-family: CaslonIonic;
  font-weight: 300;
  /*font-family: "Times New Roman";*/
}

.background .layer {
  opacity: 0.1;
  background-color: rgb(186,190,201);
}

a.link {
  border-color: rgb(255,237,1);
  border-color: black;
  background-color: transparent;
  color: rgb(7,68,122);
  color: black
}
a.link:hover {
  background-color: black;
  color: white;

  /*text-stroke: #000000 1px;*/
  /*-webkit-text-stroke: #000000 1px;*/
}
ul.socials > li > a {
  color: transparent;
  text-stroke: #000000 1.5px;
  -webkit-text-stroke: #000000 1.5px;
}
ul.socials > li > a:hover {
  color: rgb(228,103,91);
}
</style>