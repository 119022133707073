import {createRouter, createWebHistory} from 'vue-router'
import ItmightgetloudView from "@/views/ItmightgetloudView";
import WisenheimerView from "@/views/WisenheimerView";
import AlbumView from "@/views/AlbumView";
import RemixView from "@/views/RemixView";


const routes = [
    {path: '/', name: 'landing', component: RemixView},
    {path: '/reixperience', name: 'landing', component: RemixView},



    {path: '/album', name: 'album', component: AlbumView},
    {path: '/:pathMatch(.*)*', name: 'page 404', component: RemixView},

    {path: '/itmightgetloud', name: 'itmightgetloud', component: ItmightgetloudView},
    {path: '/wisenheimerwaltz', name: 'wisenheimerwaltz', component: WisenheimerView},
    {path: '/wisenheimer', name: 'wisenheimer', component: WisenheimerView},
    {path: '/dumbogetsmad', name: 'dumbogetsmad', component: WisenheimerView},
    {path: '/wisenheimer-dumbo', name: 'wisenheimer-dumbo', component: WisenheimerView},


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                // behavior: 'smooth',
                top: 80,

            }
        }
    }
})

router.beforeResolve(() => {
})
router.beforeEach(() => {
    window.scrollTo(0, 0);
});

export default router
